<template>
  <div class="login">
    <div style="display: flex;align-items: center;margin-top: 10px">
      <img src="../assets/logo.png" style="width: 50px;height: 50px" alt="logo"/>
      <div class="logo">海葵分贝</div>
    </div>
    <div class="pad">
      <div class="login-form">
        <div class="form-title">{{$route.query.title}}</div>
        <div class="form">
          <template>
            <div class="form-item form-tips">
              <div class="tips">未注册的手机号验证后自动创建帐号</div>
              <div style="display: flex;justify-content: space-between;">
                <el-select v-model="region"  @change="selectChange" :popper-append-to-body="true" >
                  <el-option class="option" style="width:230px;"
                             v-for="(item,index) in options"
                             :key="index"
                             :value="'+'+item.tel"
                  >
                    {{item.name}}({{'+'+item.tel}})
                  </el-option>
                </el-select>
                <input
                    class="input phoneStyle"
                    maxlength="11"
                    placeholder="请输入手机号码"
                    v-model="mobile"
                /></div>

            </div>
            <div class="form-item">
              <input
                  class="input"
                  placeholder="请输入验证码"
                  v-model="code"
              />
              <a
                  href="javascript:;"
                  class="send-code"
                  :class="{ disabled: !canSend }"
                  @click="sendCode"
              >
                {{ timeLeft == 60 ? '获取验证码' : `剩余${timeLeft}秒` }}
              </a>
            </div>
            <div class="form-item">
              <input
                  v-model="nickName"
                  class="input"
                  placeholder="请输入您的昵称"
              />
            </div>
          </template>

          <div class="form-action">
            <el-button class="submit" :loading="submitbol" @click="login">登录</el-button>
          </div>
          <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 7px;">
            <div class="agreement" @click="agreed = !agreed">
              <img v-if="agreed" src="../assets/checked.png" class="icon"  alt=""/>
              <img v-else src="../assets/check.png" class="icon"  alt=""/>
              <div class="text">
                同意<span @click.stop.prevent="goPrivacy">《隐私政策》</span
              >及<span @click.stop.prevent="goAgreement"
              >《海葵分贝服务条款》</span
              >
              </div>
            </div>
            <router-link to="/" style="text-decoration: none;" >
              <div style="cursor:pointer;font-size: 12px;font-family: PingFang SC, PingFang SC-Regular;font-weight: 400;text-align: LEFT;color: #999999;">返回首页</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {goAgreement, goPrivacy} from "@/utils/utils"
import countryPhone from "@/utils/countryPhone"
import * as CONST from "@/utils/const";

export default {
  name: "LoginAndJoin",
  data() {
    return {
      mobileRegExp: /\d{7,11}$/, // 手机号校验正则
      pass: '',
      submitbol: false,
      agreed: true, // 是否勾选了同意协议
      region:'+86',
      regionstr:'86',
      options: countryPhone,
      timeLeft: 60,
      code: '',
      mobile: '',
      title:'',
      companyId:undefined,
      smsMessage:true,
      nickName:undefined,
    }
  },
  methods: {
    goPrivacy,
    goAgreement,
    selectChange(val) {
      //字符串截取获得区号
      this.regionstr = val.substring(1, val.length)
    },
    sendCode() {
      if (this.timeLeft < 60 || !this.smsMessage) {
        return false
      }
      if (!this.canSend) {
        return
      }
      this.smsMessage = false;
      this.$post('/api/sms/send', {
        zone: this.regionstr,
        phone: this.mobile,
        type: 2,
        cs: parseInt(Date.now() / 1000)
      }).then((res) => {
        this.smsMessage = true;
        console.log('发送验证码结果', res)
        // 开始倒计时
        this.countDown()
      })
    },
    // 开始倒计时
    countDown() {
      this.timeLeft--
      if (this.interval) {
        this.clearInterval()
      }
      this.interval = setInterval(() => {
        this.timeLeft--
        if (this.timeLeft <= 0) {
          this.clearInterval()
          this.timeLeft = 60
        }
      }, 1000)
    },
    // 取消倒计时
    clearInterval() {
      clearInterval(this.interval)
      this.interval = null
    },
    // 统一登录方法
    login() {
      if (!this.agreed) {
        return this.$toast('请阅读并同意服务条款')
      }
      if(!this.nickName){
        return this.$toast('请输入昵称');
      }
      this.loginByMobile();
    },
    // 使用手机号和验证码登录
    loginByMobile() {
      if (!this.mobileRegExp.test(this.mobile) || this.code == '') {
        return this.$toast('请输入手机号和验证码')
      }
      this.submitbol = true;
      this.$post('/user/loginBySms', {
        acode: this.code,
        appVer: CONST.VERSION,
        devCode: CONST.DEV_CODE, //  navigator.userAgent
        devName: navigator.userAgent,
        os: CONST.OS_CODE,
        osVer: 'mp',
        phone: this.mobile,
        zone: this.regionstr
        // "pid"       : '',
        // "pushId": ""
      }).then(this.loginCallback)
    },
    loginCallback({data}) {
      this.submitbol = false;
      // console.log(data,"1")
      this.$store.commit('LOGIN', {
        secret: data.secret,
        session: data.session,
        user: data.user
      })
      this.$router.push({path:`/invitation-details/${this.$route.query.code}`, query:{login:'login',nickName:this.nickName}});
    },
  },
  computed: {
    canSend() {
      return this.timeLeft == 60 && this.mobileRegExp.test(this.mobile)
    }
  },
}
</script>

<style scoped lang="scss">
.login{
  width: 100%;
  height: 100vh;
  background-image: url("../assets/login/background.jpg");
  background-repeat:no-repeat;
  background-attachment:fixed;
  background-position:center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logo{
  margin-left: 12px;
  font-size: 28px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #ffffff;
}
.pad{
  margin-top: 37px;
  width: 690px;
  height: 478px;
  background-color: white;
}
.form-title{
  font-size: 20px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  color: #222222;
  margin-top: 54px;
  font-size: 20px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  text-align: LEFT;
  color: #222222;
}

.login-form {
  background: #ffffff;
  opacity: 0.9;
  box-sizing: border-box;
  padding: 0 120px;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;

  .form {
    flex: 1;
  }
  .form-item {
    position: relative;
    padding: 10px 0;
    .input {
      width:450px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #e2e6e7;
      padding-left: 12px;
      border-radius: 2px;
      box-sizing: border-box;
      outline: none;
      display: flex;
      align-items: center;
      &::-webkit-input-placeholder {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
        line-height: 21px;
      }
    }
    .send-code {
      width: 80px;
      height: 36px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border-radius: 2px;
      background: $base-color;
      color: #ffffff;
      line-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 13px;
      right: 3px;
      text-decoration: none;
      &.disabled {
        color: #666666;
        background: #f0f4f5;
      }
    }
  }
  .form-tips {
    padding-bottom: 0;
    padding-left: 2px;
    margin-bottom:24px;
    ::v-deep .el-input__inner {
      border: 1px solid #dedede;
      padding-left: 12px;
      height: 42px;
      box-sizing: border-box;
      border-radius: 2px;
    }

    .tips {
      margin: {
        top:18px;
        bottom: 26px;
      };
      height: 14px;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 14px;
    }
    .phoneStyle{
      width: 314px;
      display: inline-block;
    }
    .el-select{
      display: inline-block;
      width:120px;
      box-sizing: border-box;
      .option{
        width: 230px;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color:#666666;
        letter-spacing: 0px;
      }
    }
  }

  .form-action {
    margin-top: 14px;
    .submit {
      width: 450px;
      height: 42px;
      border-radius: 2px;
      background: $base-color;
      border: none;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 21px;

      /*  display: flex;
              align-items: center;
              justify-content: center; */
    }
  }
  .qrcode {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-flow: column;
    > img {
      width: 172px;
      height: 172px;
      display: block;
    }
    > .title {
      margin-bottom: 22px;
      p {
        margin: 0px;
      }
      p:nth-child(1) {
        text-align: center;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        line-height: 22px;
      }
      p:nth-child(2) {
        text-align: center;
        margin-top: 4px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
      }
    }
  }
  .agreement {
    margin-top: 10px;
    display: flex;
    height: 14px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 14px;
    .icon {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
    .text {
      span {
        cursor: pointer;
      }
    }
  }
  .other {
    height: 105px;
    .title {
      /* margin-top: 29px; */
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 21px;
    }
    .thirds {
      margin-top: 14px;
      padding-bottom: 40px;
      display: flex;
      .item {
        width: 40px;
        height: 40px;
        margin-right: 19px;
        .icon {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }

  .corner {
    width: 52px;
    height: 52px;
    position: absolute;
    right: 5px;
    top: 5px;
    .icon {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
</style>
